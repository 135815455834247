import {
  handleQueryResolve
} from '../utils'

export default function (PatientID) {
  let where
  let attrs
  if (Array.isArray(PatientID)) {
    // FIXME Filter the PatientIDs
    where = [`dos.PatientID IN (${PatientID.join(',')})`]
  } else {
    attrs = [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID, // to support like
    }]
    where = ['dos.PatientID = @PatientID']
  }
  const query = `
SELECT dos.RxNumber
    ,pat.FirstName
    ,pat.LastName
    ,dos.PatientID
    ,dos.Brk
    ,dos.Noon
    ,dos.Supp
    ,dos.Bed
    ,dos.IsInactive
    ,dos.ModifiedOn
    ,dos.Modification
    ,rx.DIN
    ,rx.QtyDispense
    ,rx.SIGFull
    ,rx.RxDate
    ,drug.GenericDrug
    ,drug.GenericName
    ,drug.Strength
    ,drug.BrandName
    ,(drug.Color + ' ' + drug.Shape + ' ' + drug.Form) as description
FROM [Fillware].[dbo].[DosR] as dos
INNER JOIN Fillware.dbo.Rx as rx ON dos.RxNumber = rx.RXNumber
INNER JOIN Fillware.dbo.DrugRoot as drug on rx.DIN = drug.DIN
INNER JOIN Fillware.dbo.Patient as pat on dos.PatientID = pat.PatientID
WHERE dos.IsInactive = 0 AND ${where}
    ORDER BY dos.PatientID DESC
`

  // console.log(query)

  return this.query(query, attrs).then(handleQueryResolve)
}
